

































import { Component, Vue } from 'vue-property-decorator';
import ChangePassword from '@/components/login/ChangePassword.vue';
import ValidChangePassword from '@/components/login/ValidChangePassword.vue';
import { user, toast } from '@/store';
import { pagePath, ReferenceToken } from '@/utils';
import { AuthenticationService } from '@/services/authentication.services';
import { ERRORTYPE, IsNullOrEmpty, LocalStorageHelper } from '@projetlucie/lc-front-sdk-old';
import router from '@/router';

@Component({
  components: {
    ChangePassword,
    ValidChangePassword
  }
})
export default class ResetPassword extends Vue {
  private isPasswordValid = false;

  public async goToValidatedPassword(value: string) {
    if (IsNullOrEmpty(LocalStorageHelper.getItem(ReferenceToken))) {
      this.showErrorToast();
      router.replace(pagePath.Home);
    }

    const isValidResponse = await AuthenticationService.createPassword(
      LocalStorageHelper.getItem(ReferenceToken) ?? '',
      value,
      this.connectedUser.idu
    );
    this.isPasswordValid = isValidResponse;
    if (isValidResponse) {
      LocalStorageHelper.removeItem(ReferenceToken);
    } else {
      this.showErrorToast();
      router.replace(pagePath.Home);
    }
  }

  private showErrorToast() {
    toast.createAndPushToast(
      this.$t('resetPassword.error.title').toString(),
      this.$t('resetPassword.error.subtitle').toString(),
      true,
      ERRORTYPE.DANGER
    );
  }

  get connectedUser() {
    return user.userConnected;
  }
}
